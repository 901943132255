/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import Layout from '../layout';
import SEO from '../components/SEO/SEO';
import config from '../../data/SiteConfig';
import useAnalytics from '../hooks/useAnalytics';

// components
import Tabs from '../components/common/Tabs/Tabs';

import { renderTable } from '../utils';

import { getLocale, getMessages } from '../locales';
import Privacy from '../components/Privacy';

function PrivacyPage() {
  const { trackEvent } = useAnalytics();
  trackEvent('View Privacy Policy Page');
  const messages = getMessages();
  const locale = getLocale();
  const privacyContent = Privacy[locale];

  return (
    <Layout hideNVResidentsDisclosure hideNYResidentsDisclosure hideNMLS>
      <div className="landing-container">
        <SEO metadata={{ title: config.privacyTitle }} />
        <section className="privacy-policies container">
          <Tabs
            options={messages['privacy.tabs']}
            activeTab={0}
            onClick={() => trackEvent('Click Privacy Policy Tab', { Type: 'CA' })}
          />
          <div className="privacy-content">
            <section className="privacy-header">
              <h1>{messages['privacy.title']}</h1>
            </section>
            <div className="content">{privacyContent}</div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default PrivacyPage;
